<template>
	<div class="main-gallery">
		<div class="main-gallery__top">
			<div class="main-gallery__title">
				<h2 class="main-gallery__hdng">
					{{ title }}
				</h2>
				<span v-if="subTitle" class="main-gallery__sub-title">
					{{ subTitle }}
				</span>
			</div>
		</div>

		<div class="main-gallery__content">
			<slot>
				<transition :name="'fade'" mode="out-in">
					<or-slider
						v-slot="{ item }"
						:collectionData="skeletonList"
						v-if="loading"
						:sliderProperties="{ pagination: true }"
						@cardClick="cardClick"
					>
						<component
							:content="item"
							:is="'SkeletonCard'"
							:isLike="isLike"
							:options="options"
							:size="typeCard"
						/>
					</or-slider>
					<or-slider
						v-slot="{ item }"
						:collectionData="content"
						v-else
						:sliderProperties="{ pagination: true }"
						@cardClick="cardClick"
					>
						<component
							:content="item"
							:is="card"
							:isLike="isLike"
							:options="options"
							:isLoading="loading"
						/>
					</or-slider>
				</transition>
			</slot>
		</div>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import SkeletonCard from '@/components/cards/Skeleton'
import GalleryBtnSvg from '@/components/svg/GalleryBtn'

export default {
	name: 'Gallery',
	components: {
		AreasCard: defineAsyncComponent(() => import('@/components/cards/Common')),
		ProjectCard: defineAsyncComponent(() =>
			import('@/components/cards/Project')
		),
		HoundingTypeCard: defineAsyncComponent(() =>
			import('@/modules/home/components/cards/HousingTypes')
		),
		RoomCard: defineAsyncComponent(() => import('@/components/cards/Room')),
		EstateCard: defineAsyncComponent(() => import('@/components/cards/Estate')),
		SkeletonCard,
		GalleryBtnSvg
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		subTitle: {
			type: String,
			default: ''
		},
		content: {
			type: Array,
			default: () => []
		},
		loading: {
			type: Boolean,
			default: false
		},
		isLike: {
			type: Boolean,
			default: false
		},
		card: {
			type: String,
			required: true,
			default: 'MainCard'
		},
		typeCard: {
			type: String,
			default: 'big'
		},
		options: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		skeletonList() {
			return new Array(6)
		}
	},
	methods: {
		cardClick(event, item) {
			this.$emit('cardClick', event, item)
		}
	}
}
</script>

<style lang="scss">
.main-gallery {
	position: relative;
	.boots-slider {
		&__pagination {
			position: absolute;
			top: 0;
			right: 0;
		}
		&__slide {
			.estate-card {
				img {
					width: 280px;
				}
			}
		}
	}
	&__top {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 20px;
		margin-bottom: 30px;
	}

	&__hdng {
		margin: 0;
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		color: #2a3043;
	}

	&__sub-title {
		display: inline-block;
		margin-top: 10px;
		font-size: 14px;
		line-height: 16px;
		color: #afafaf;
	}

	&__btns {
		display: flex;
		gap: 5px;
	}

	&__content {
		padding: 0;
		display: flex;
		justify-content: space-between;
		gap: 20px;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: transparent;
		}
	}

	&__loader {
		margin: 0 auto;
	}

	&__carousel {
		width: 100%;

		.carousel__viewport {
			.carousel__track {
				grid-gap: 10px;
				align-items: flex-start;
				margin: 0;

				img {
					object-fit: cover;
				}
			}
		}
	}
	@media screen and (max-width: 700px) {
		&__btns {
			display: none;
		}
	}
}
</style>
