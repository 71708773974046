<template>
	<div class="skeleton-card" :class="className">
		<or-skeleton class="skeleton-card__img skeleton-img" />
		<or-skeleton class="skeleton-card__text" />
		<or-skeleton class="skeleton-card__text" />
	</div>
</template>

<script>
export default {
	name: 'Skeleton',
	props: {
		loading: Boolean,
		size: {
			type: String,
			default: 'big'
		}
	},
	computed: {
		className() {
			return {
				'skeleton-card--small': this.size === 'small',
				'skeleton-card--big': this.size === 'big'
			}
		}
	}
}
</script>

<style lang="scss">
.skeleton-card {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;

	&--small {
		.skeleton-img {
			width: 280px;
			aspect-ratio: 16 / 10;
			height: 100%;
		}
	}

	&--big {
		.skeleton-img {
			height: 310px;
		}
	}

	&__text {
		width: 100px;
		height: 19px;
	}
}
</style>
